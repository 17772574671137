import React from 'react';
import './App.css';
import styled from 'styled-components';

let words = [
  'Sono scemo',
  'Sono scemo',
  'Leave empty bottles around',
  'Urlo "Attenzione"!!!',
  'Prevent Dyson usage',
  'Don\'t allow to hang laundry',
  'Non butto mai la spazzatura',
  'Mangio il cioccolato davanti a te',
  'Make you do 20km trekking pregnant',
  'Take pictures while you are passed out on couch',
  'Wipe my mouth after a kiss',
  'Have a supermarket ordering regime',
  'Dico "Prima pensi"',
  'Wake you like crazy when watching Netflix',
  'Mi scaccolo il tesoro',
  'Stare at your hair',
  'Say that I\'m one year younger',
  'Re-check if door is locked',
];

const shuffle = () => {
  words = words
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

interface OwnState {
  wordsDone: boolean;
}

class Words extends React.Component<{}, OwnState> {
  private remaining = words.length;

  constructor(props: any) {
    super(props);
    this.state = {
      wordsDone: false,
    }
    shuffle();
  }

  restart = () => {
    setTimeout(() => {
      shuffle();
      this.remaining = words.length;
      this.setState({ wordsDone: false });
    }, 5000);
  };

  removeWord = () => {
    this.remaining--;
    if (this.remaining === 0) {
      this.setState({ wordsDone: true });
    }
    console.debug(`remainingWords=${this.remaining}`);
  };

  render() {
    console.log('render');
    if (this.state.wordsDone) {
      return (
        <div>
          <h1 className='Blinking'>
            Pero ti amo!!!
          </h1>
          <img src='image1.jpg' className="Amore" alt="sonoscemo" onAnimationEnd={() => this.restart()} />
        </div>
      )
      return ;
    }
    let num = -1;
    return words.map(x => {
      const id = `num${num}`;
      num++;
      const Span = styled.span`
        --index: ${num + 1};
        --random: ${Math.random() * 2.5}
      `;
      return (<Span
        id={id}
        key={x}
        className='SentenceMove'
        onAnimationEnd={() => this.removeWord()}
        >
      {x}
      </Span>);
    });
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Words/>
      </header>
    </div>
  );
}

export default App;
